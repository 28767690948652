/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Nabídka služeb a ceník"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-d6fybz pb--60 pt--60" name={"uvod"} style={{"backgroundColor":"var(--color-supplementary)"}} layout={"l13"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --left el--1 flex--top" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper className="--center pb--25 pt--40" style={{"maxWidth":""}}>
              
              <Title className="title-box fs--72" content={"<span style=\"color: rgb(255, 255, 255); font-style: italic;\">Nabídka služeb a ceník</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"kompletni-nabidka"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" style={{"maxWidth":1080}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--48" content={"nová modeláž nehtů<br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box" content={"Pracuji s&nbsp; německou kvalitní značkou ALESSANDRO, jedinečná technologie.<br>Stavební gel je vhodný na všechny typy nehtů.<br>Novou modeláž nastavuji na tipy.&nbsp;<br><br>práce 2,5 - 3 hodiny<br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"950,- Kč"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--top" style={{"maxWidth":1080}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--48" content={"doplnění gelu"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box" content={"po 3 - 4 týdnech se gel doplňuje<br><br>práce 1,5 - 2 hodiny<br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"750,— Kč"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--top" style={{"maxWidth":1080}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--48" content={"odstranění gelu&nbsp;"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box" content={"a následné ošetření přírodních nehtů<br><br>práce 1 hodina<br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"550,— Kč"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--top" style={{"maxWidth":1080}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--48" content={"CND Shellac<br><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box" content={"Unikátní patentovaná UV 3 technologie kombinující snadnost lakování s odolností gelu. Neodlupuje se, extrémní barevná stálost, vydrží minimálně 14 dní!<br>Odstranění pomocí polštářků s profi acetonem.<br>práce 1 - 1,5 hodiny<br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"600,— Kč"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--top" style={{"maxWidth":1080}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--48" content={"Manikúra"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box" content={"Úprava délky nehtů a kutikuly. Následuje příjemná relaxační masáž rukou, pomocí peelingu docílím zjemnění Vašich rukou. Zábalem s maskou z&nbsp; mořských řas se pokožka důkladně vyživí. Závěrem manikúry je ošetření bambuckým máslem<br><br>práce 1 hodina&nbsp;"}>
              </Text>

              <Subtitle className="subtitle-box" content={"600,— Kč"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
            </ColumnWrapper>

            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--top" style={{"maxWidth":1080}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--48" content={"Pedikúra - Lázeňská Basic"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box" content={"Po krátké odpočinkové vodní lázni se solí s éterickými oleji z horské sosny, rozmarýnu a levandule, budou nohy v zábalu. Úprava nehtů a kutilkuly. Pomocí Créda a skalpelu se odstraní zrohovatělá kůže, popřípadě odstranění kuřích ok a ošetření zarůstajících nehtů. Chodidla se vyhladí pomocí jemné hrubosti pilníku. Závěrečné ošetření pudrem<br><br>práce 1 hodina&nbsp;"}>
              </Text>

              <Subtitle className="subtitle-box" content={"600,— Kč"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
            </ColumnWrapper>

            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--top" style={{"maxWidth":1080}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--48" content={"Pedikúra Lázeňská Luxury"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box" content={"Luxusní ošetření nohou zahrnuje vše viz Lázeňská pedikúra Basic. Poté následuje masáž nohou, příjemný peeling značky Cuccio - přírodní mořská sůl se středně hrubými abrazivními částečkami a extrakty z granátového jablka a fíku. Předposlední fáze&nbsp;je zábal - maska z máslové emulze značky Cuccio. Závěrem ošetření pudrem<br><br>práce 1,5 hodiny"}>
              </Text>

              <Subtitle className="subtitle-box" content={"850,— Kč"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
            </ColumnWrapper>

            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--top" style={{"maxWidth":1080}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--48" content={"CND Shellac k pedikúře"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box" content={"Unikátní patentovaná UV 3 technologie kombinující snadnost lakování s odolností gelu. Neodlupuje se, extrémní barevná stálost, vydrží minimálně 6 týdnů!<br>práce 30 -45minut&nbsp;&nbsp;"}>
              </Text>

              <Subtitle className="subtitle-box" content={"380,— Kč"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
            </ColumnWrapper>

            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"paticka"} style={{"backgroundColor":"rgba(211,155,192,1)"}}>
          
          <ColumnWrap className="column__flex el--3 flex--center" anim={""} animS={"2"} style={{"maxWidth":1420,"backgroundColor":"var(--color-custom-1)"}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Profesionální</span>"}>
              </Subtitle>

              <Title className="title-box mt--02" content={"<span style=\"color: var(--color-dominant);\">Péče o<br>Vaše ruce a nohy<br><br>Renata Juránková<br>Studio VeNo<br>V Horkách 1389/11<br>Praha 4 Nusle<br>140 00<br></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}